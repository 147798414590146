const router = [
  // роутер :)

  // empty
  {
    name: 'app.root',
    abstract: true,
    template: '<linda-app linda-zendesk></linda-app>',
  },
  {
    name: 'app.root.layer1.layer2.registration-bank',
    url: '/fast-deposit',
    template: '<linda-zimpler-paynplay-page></linda-zimpler-paynplay-page>',
  },
  {
    name: 'app.root.paymentcb',
    url: '/payment/:act?order_id&identifier',
    template: '<linda-payment-callback ></linda-payment-callback>',
  },
  {
    name: 'app.root.withdraw-paymentcb-success',
    url: '/withdraw-payment/success',
    template: '<linda-withdrawal-payment-callback></linda-withdrawal-payment-callback>',
    params: {
      status: 'success',
    },
  },
  {
    name: 'app.root.withdraw-paymentcb-fail',
    url: '/withdraw-payment/fail',
    template: '<linda-withdrawal-payment-callback></linda-withdrawal-payment-callback>',
    params: {
      status: 'fail',
    },
  },
  {
    name: 'app.root.mobilegame',
    url: '/mobilegame/:name/:mode',
    template: '<linda-game-field-mobile></linda-game-field-mobile>',
  },
  // plus sidebar header
  {
    name: 'app.root.layer1',
    abstract: true,
    template: '<linda-view-layer-one></linda-view-layer-one>',
  },
  {
    name: 'app.root.game',
    abstract: true,
    template: '<linda-game-page></linda-game-page>',
    gamePage: true,
    //        params: {
    //            redirectIfMobile: 'app.root.mobilegame'
    //        }
  },

  // <MACHINES>

  {
    name: 'app.root.claw_games',
    abstract: true,
    private: true,
    template: '<ui-view></ui-view>',
  },
  {
    name: 'app.root.claw_games.gamepage',
    url: '/machine/:name',
    template: '<batman-view-layer1 />',
  },

  // </MACHINES>

  /*
    {
        name: 'app.root.layer1.cash-crab',
        url: '/play/cash-crab',
        template: '<batman-view-layer1 />',
        private: true,
    },
    {
        name: 'app.root.layer1.cash_crab',
        url: '/play/cash_crab',
        template: '<batman-view-layer2 />',
        private: true,
    },
*/
  {
    name: 'app.root.game.real',
    url: '/play/:name',
    template: '<linda-real-game-footer game="game"  ></linda-real-game-footer>',
    private: true,
    params: {
      mode: 'real',
    },
  },
  {
    name: 'app.root.game.demo',
    url: '/game/:name',
    template: '<linda-demo-game-footer  game="game"></linda-demo-game-footer>',
    params: {
      mode: 'demo',
    },
  },
  {
    name: 'app.root.layer1.layer2.not-found',
    url: '/404',
    template: '<not-found></not-found>',
  },
  // ======== plus footer ========
  {
    name: 'app.root.layer1.layer2',
    abstract: true,
    template: '<linda-view-layer-two></linda-view-layer-two>',
  },
  {
    name: 'app.root.layer1.layer2.sitemap',
    url: '/sitemap',
    template: '<linda-sitemap  scroll-to-top="" ></linda-sitemap>',
  },
  {
    name: 'app.root.layer1.layer2.privacy-policy',
    url: '/privacy-policy',
    template: '<linda-info-page  scroll-to-top="" ></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'privacy-policy',
    },
  },
  {
    name: 'app.root.layer1.layer2.cookies-policy',
    url: '/cookies-policy',
    template: '<linda-info-page scroll-to-top></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'cookies-policy',
    },
  },
  {
    name: 'app.root.layer1.layer2.about',
    url: '/about',
    template: '<linda-info-page  scroll-to-top="" ></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'about',
    },
  },
  {
    name: 'app.root.layer1.layer2.responsible-gaming',
    url: '/responsible-gaming',
    template: '<linda-info-page  scroll-to-top="" ></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'responsible-gaming',
    },
  },
  {
    name: 'app.root.layer1.layer2.rules',
    url: '/rules',
    template: '<linda-info-page  scroll-to-top="" ></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'rules',
    },
  },
  {
    name: 'app.root.layer1.layer2.faq',
    url: '/faq',
    template: '<linda-info-page  scroll-to-top="" ></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'faq',
    },
  },
  {
    name: 'app.root.layer1.layer2.contact-us',
    url: '/contact-us',
    template: '<linda-info-page  scroll-to-top="" ></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'contact-us',
    },
  },

  // profile
  {
    name: 'app.root.layer1.layer2.account',
    abstract: true,
    template: '<linda-profile-page></linda-profile-page>',
    private: true,
  },
  {
    name: 'app.root.layer1.layer2.account.staff',
    url: '/account/staff',
    template: '<linda-profile-staff></linda-profile-staff>',
  },
  {
    name: 'app.root.layer1.layer2.account.details',
    url: '/account/details',
    template: '<linda-profile-settings></linda-profile-settings>',
  },
  {
    name: 'app.root.layer1.layer2.account.verification',
    url: '/account/verification',
    template: '<batman-ui-wrapper1 scroll-to-top></batman-ui-wrapper1>',
  },
  {
    name: 'app.root.layer1.layer2.account.balance',
    url: '/account/balance',
    template: '<linda-cashbox-balance></linda-cashbox-balance>',
  },
  {
    name: 'app.root.layer1.layer2.account.history',
    url: '/account/history/:type',
    template: '<linda-cashbox-history></linda-cashbox-history>',
    params: {
      type: 'deposit',
    },
  },

  // games
  {
    name: 'app.root.layer1.layer2.layer3.catalog',
    abstract: true,
    template: '<linda-view-layer-five></linda-view-layer-five>',
  },
  {
    name: 'app.root.layer1.layer2.layer3.catalog.favourites',
    url: '/games/favourites',
    template: '<batman-game-box-favourites1></batman-game-box-favourites1>',
    params: {
      collection: 'games',
    },
  },
  {
    name: 'app.root.layer1.layer2.layer3.catalog.providers',
    url: '/casino-providers/:name',
    template: '<linda-game-hall-providers    ></linda-game-hall-providers>',
    params: {
      pageName: 'providers',
      pageType: '',
      collection: 'games',
    },
  },

  // =======  plus banner  =======
  {
    name: 'app.root.layer1.layer2.layer3',
    abstract: true,
    template: '<linda-view-layer-three></linda-view-layer-three>',
  },
  {
    name: 'app.root.layer1.layer2.layer3.home',
    url: '/',
    template: '<linda-homepage></linda-homepage>',
  },
  {
    name: 'app.root.layer1.layer2.layer3.promos',
    url: '/promotions',
    template: '<linda-promos></linda-promos>',
  },
  {
    name: 'app.root.layer1.layer2.layer3.tournaments',
    url: '/tournaments',
    template: '<linda-tournaments></linda-tournaments>',
  },
  {
    name: 'app.root.layer1.layer2.layer3.vip',
    url: '/vip-service',
    template: '<linda-vip></linda-vip>',
  },

  // ========= app ===========

  // {
  //   name: 'app.root.layer1.layer2.app',
  //   url: '/app',
  //   template: '<linda-mobile-app></linda-mobile-app>',
  // },

  {
    name: 'app.root.missed-data',
    url: '/missed-data',
    template: '<linda-missed-data-form></linda-missed-data-form>',
  },
  {
    name: 'app.root.layer1.layer2.seasons',
    url: '/seasons/:name',
    template: `<linda-promo-wrapper scroll-to-top=""></linda-promo-wrapper>`,
  },
  {
    name: 'app.root.layer1.layer2.layer3.catalog.collection',
    url: '/:collection',
    template: '<batman-game-hall1 scroll-to-top=""></batman-game-hall1>',
    params: {
      collection: 'games',
    },
    onEnter: [
      'casinoResolver',
      function (casinoResolver) {
        casinoResolver.collection();
      },
    ],
  },
  {
    name: 'app.root.layer1.layer2.layer3.catalog.games',
    url: '/:collection/:name',
    template: '<batman-game-hall2 scroll-to-top=""></batman-game-hall2>',
    params: {
      collection: 'games',
      name: 'top',
    },
    onEnter: [
      'casinoResolver',
      function (casinoResolver) {
        casinoResolver.collection();
      },
    ],
  },
];

export { router };
